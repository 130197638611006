body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.gm-style .gm-style-iw-c{
  background: transparent !important;
  box-shadow: none !important;
  padding: 0px !important;
}
.gm-style .gm-style-iw-d{
  overflow:hidden !important;
}
.gm-ui-hover-effect>span{
  background-color:#fff;
}
.gm-style .gm-style-iw-tc::after{
  background: #fff!important;
  height: 15px;
  width: 20px;
}

/* TODO: delete this file */