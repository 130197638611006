.demo-app {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
  }
  
  .demo-app-top {
    margin: 0 0 3em;
  }
  
  .demo-app-calendar {
    margin: 0 auto;
    max-width: 900px;
  }
  